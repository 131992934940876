<script setup lang="ts">
import type { SbBlokData } from '@storyblok/js';

interface IProps {
    blok: GlobalCtaFixedUpperBannerBlokInterface;
    cookieName?: string;
    show?: boolean;
}
const props = withDefaults(defineProps<IProps>(), {
    cookieName: 'lfc-fix-up-ban',
    show: true,
});

const route = useRoute();

const hidden = ref(false);
const delayHasPassed = ref(false);
const hardHidden = ref(true);

const cShow = computed(() => {
    return props.show && !hidden.value && delayHasPassed.value;
});

watch(cShow, (newValue: boolean) => {
    if (newValue) {
        hardHidden.value = false;
    } else {
        setTimeout(() => {
            hardHidden.value = true;
        }, 500); // 0.5 seconds, match to CSS ease-in-out
    }
});

onMounted(() => {
    const isPreview = route.query.preview;
    const isInGlobal = route.path.startsWith('/global/');
    const hiddenByCookie = cookieIsSet(props.cookieName);
    if (!isInGlobal && (hiddenByCookie || isPreview)) {
        hideBanner();
    }

    // Show after `delaySeconds` seconds
    let delaySeconds = props.blok.delaySeconds || 0;
    if (isInGlobal) {
        delaySeconds = 0;
    }
    setTimeout(() => {
        delayHasPassed.value = true;
    }, delaySeconds * 1000); // in milliseconds
});

const hideBanner = () => {
    hidden.value = true;
};

const dismiss = () => {
    hideBanner();
    setCookie(props.cookieName, '1', 30);
};
</script>

<template>
    <div v-editable="blok">
        <div data-global-cta-fixed-upper-banner>
            <div
                ref="banner"
                class="EsFixedBanner bg-white shadow"
                :class="{ 'EsFixedBanner-hide': !cShow }">
                <div :class="{ 'd-none': hardHidden }">
                    <es-container>
                        <es-row class="justify-content-center text-center">
                            <es-col class="position-relative pt-200 pt-sm-100">
                                <h2
                                    v-if="blok.heading"
                                    class="font-weight-bold mb-25">
                                    {{ blok.heading }}
                                </h2>
                                <p
                                    v-if="blok.subtitle"
                                    class="font-size-100 font-size-lg-300 mb-0">
                                    {{ blok.subtitle }}
                                </p>
                                <div
                                    role="button"
                                    aria-label="Close banner"
                                    tabindex="0"
                                    class="CloseButton"
                                    @click="dismiss"
                                    @keyup="dismiss">
                                    <icon-x />
                                </div>
                                <es-row class="justify-content-center my-100">
                                    <es-col
                                        order="3"
                                        cols="12"
                                        lg="5"
                                        class="d-flex flex-column justify-content-center">
                                        <template
                                            v-for="childBlok in blok.cta"
                                            :key="childBlok._uid">
                                            <storyblok-component
                                                :blok="childBlok as unknown as SbBlokData"
                                                class="justify-content-center" />
                                        </template>
                                    </es-col>
                                </es-row>
                            </es-col>
                        </es-row>
                    </es-container>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
@use '@energysage/es-ds-styles/scss/mixins/breakpoints';
@use '~/assets/scss/constants';

.CloseButton {
    position: absolute;
    right: 10px;
    top: 0.5rem;
}

.EsFixedBanner {
    left: 0;
    opacity: 1;
    position: fixed;
    right: 0;
    top: 0;
    transition: opacity 0.5s ease-in-out;
    z-index: 900;

    @include breakpoints.media-breakpoint-up(lg) {
        top: constants.$sticky-navbar-height;
    }
}

.EsFixedBanner-hide {
    opacity: 0;
}
</style>
